<template>
  <v-app id="app" class="transparent-background">
    <v-main
      :class="
        loading ? 'black' : $route.name === 'Player' ? 'transparent' : 'neutral'
      "
    >
      <div
        v-if="loading"
        class="d-flex align-center justify-center fill-height"
      >
        <v-progress-circular :size="64" color="white" indeterminate />
      </div>

      <router-view
        v-else
        :videoID="videoID"
        :videoToken="videoToken"
        :userToken="userToken"
        :settings="settings"
        :videoOptions="options"
        :stopTracking="stopTracking"
      />
    </v-main>
  </v-app>
</template>

<script>
import { request } from "@/services";

export default {
  name: "App",

  data() {
    return {
      loading: true,
      langs: ["pt", "es"],
      videoID: "",
      videoToken: "",
      userToken: "",
      stopTracking: false,
      settings: {
        colors: {
          primary: "primary",
          secondary: "secondary",
        },
        border: {
          show: true,
          color: "",
          size: "8",
          rounded: false,
        },
        reproductionButton: true,
        progressBar: true,
        timer: true,
        volume: true,
        fullscreen: true,
      },
      options: {
        thumbnails: {
          defaultThumb: "",
        },
        autoPlay: {
          enabled: false,
          startAt: "",
          endAt: "",
          title: "",
          subtitle: "",
          cardColor: "",
          textColor: "",
          gifURL: "",
        },
        resumePlay: {
          enabled: false,
          title: "",
          continueLabel: "",
          restartLabel: "",
          textColor: "",
          cardColor: "",
        },
        smartProgress: {
          enabled: false,
          height: "8",
        },
        actionButtons: [],
        backgroundPlayback: false,
      },
    };
  },

  beforeMount() {
    this.startSession();
  },

  methods: {
    async startSession() {
      try {
        const params = this.$route.query;

        if (!params.hash) return;

        const lang = params.lang;
        const adminMode = Boolean(params.adminMode);

        if (lang && this.langs.includes(lang)) this.$i18n.locale = lang;
        if (adminMode) this.stopTracking = true;

        let payload = {
          method: "getVideobyHash",
          videoHash: params.hash,
        };

        Object.keys(params).forEach((key) => {
          if (key !== "lang" && key !== "adminMode" && key !== "hash") {
            payload.urlParams = {
              ...payload.urlParams,
              [key]: params[key],
            };
          }
        });

        if (payload.urlParams)
          payload.urlParams = JSON.stringify(payload.urlParams);

        const { data } = await request(payload);

        this.videoID = data.video.youtubeID;
        this.videoToken = data.videoToken;
        this.userToken = data.userToken;

        // start video in background playback with adminMode query
        if (
          // adminMode &&
          data.video.videoFeatures.smartAutoplay === "0" &&
          data.video.videoFeatures.thumbs === "0"
        ) {
          this.options.backgroundPlayback = true;
        }

        this.settings = {
          colors: {
            primary: data.video.videoConfigs.corPrincipal || "primary",
            secondary: data.video.videoConfigs.corSecundaria || "secondary",
          },
          border: {
            show: data.video.videoConfigs.ativaBorda === "1",
            color: data.video.videoConfigs.corBorda || "#8FDF1C",
            size: data.video.videoConfigs.tamBorda || "1",
            rounded: data.video.videoConfigs.bordaArredondada === "1",
          },
          reproductionButton: data.video.videoConfigs.playPequeno === "1",
          progressBar: data.video.videoConfigs.barraProgresso === "1",
          timer: data.video.videoConfigs.tempoVideo === "1",
          volume: data.video.videoConfigs.volume === "1",
          fullscreen: data.video.videoConfigs.fullScreen === "1",
          videoTime: data.video.videoTime,
          verticalVideo: data.video.videoConfigs.videoVertical === "1",
        };

        if (
          data.video.videoFeatures.thumbs === "1" &&
          data.video.thumbs &&
          Object.keys(data.video.thumbs).length
        )
          this.setThumbs(data.video.thumbs);

        if (
          data.video.videoFeatures.smartAutoplay === "1" &&
          data.video.smartAutoplay &&
          Object.keys(data.video.smartAutoplay).length
        )
          this.setAutoplay({
            ...data.video.smartAutoplay,
            gifPath: data.video.gifPath,
          });

        if (
          data.video.videoFeatures.continuarAssistindo === "1" &&
          data.video.continuarAssistindo &&
          Object.keys(data.video.continuarAssistindo).length
        )
          this.setResumePlay(data.video.continuarAssistindo);

        if (
          data.video.videoFeatures.smartProgress === "1" &&
          data.video.smartProgress &&
          Object.keys(data.video.smartProgress).length
        )
          this.setSmartProgress(data.video.smartProgress);

        if (
          data.video.videoFeatures.botoesDeAcao === "1" &&
          data.video.botoesDeAcao &&
          Object.keys(data.video.botoesDeAcao).length
        )
          this.setActionButtons(data.video.botoesDeAcao);
      } catch {
        this.$router.push({ path: "/not-found" }).catch(() => {
          /* ignore */
        });
      } finally {
        this.loading = false;
      }
    },

    setThumbs(thumbnails) {
      if (!thumbnails.ativo) return;

      const url = this.$store.getters.apiURL;

      this.options.thumbnails = {
        ...(thumbnails.inicial && {
          defaultThumb: thumbnails.inicial?.imagePath
            ? url + thumbnails.inicial.imagePath
            : "",
        }),
        ...(thumbnails.final && {
          finalThumb: thumbnails.final.imagePath
            ? url + thumbnails.final.imagePath
            : "",
        }),
        ...(thumbnails.thumbs.length && {
          holdThumb: (thumbnails.thumbs || []).map((el) => url + el.image),
        }),
      };
    },

    setAutoplay(autoPlay) {
      if (!autoPlay.ativo) return;

      this.options.autoPlay.enabled = true;
      this.options.autoPlay.startAt = autoPlay.inicio;
      this.options.autoPlay.endAt = autoPlay.fim;
      this.options.autoPlay.title = autoPlay.textoSuperior;
      this.options.autoPlay.subtitle = autoPlay.textoInferior;
      this.options.autoPlay.cardColor = autoPlay.corBackground;
      this.options.autoPlay.textColor = autoPlay.corTexto;

      /*  if (/iPhone/i.test(navigator.userAgent)) {
        if (autoPlay.gifPath)
          this.options.autoPlay.gifURL =
            this.$store.getters.apiURL + "/" + autoPlay.gifPath;
        else this.options.autoPlay.enabled = false;
      } */
    },

    setResumePlay(resumePlay) {
      if (!resumePlay.ativo) return;

      this.options.resumePlay.enabled = true;
      this.options.resumePlay.title = resumePlay.mensagem;
      this.options.resumePlay.continueLabel = resumePlay.botaocontinuar;
      this.options.resumePlay.restartLabel = resumePlay.botaorecomecar;
      this.options.resumePlay.textColor = resumePlay.corTexto;
      this.options.resumePlay.cardColor = resumePlay.corBackground;
    },

    setSmartProgress(smartProgress) {
      if (!smartProgress.ativo) return;

      this.options.smartProgress.enabled = true;
      this.options.smartProgress.height = smartProgress.altura;
    },

    setActionButtons(actionButtons) {
      if (!actionButtons.ativo) return;

      this.options.actionButtons = actionButtons.botoesDeAcao
        .filter((el) => el.botao)
        .map((el) => ({
          id: el.botaoID,
          show: false,
          startTime: el.inicio,
          endTime: el.fim,
          text: el.botao.texto,
          color: el.botao.corBotao,
          textColor: el.botao.corTexto,
          hoverColor: el.botao.corHover,
          position: this.handlePosition(Number(el.botao.posicao) - 1),
          size: this.handleSize(Number(el.botao.tamanho)),
          redirectURL: el.botao.link,
        }));
    },

    handlePosition(index) {
      if (index === -1) return "outside";

      const positions = [
        "top,left",
        "top",
        "top,right",
        "left",
        "center",
        "right",
        "bottom,left",
        "bottom",
        "bottom,right",
      ];

      return positions[index];
    },

    handleSize(index) {
      const sizes = ["small", "medium", "large"];

      return sizes[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.transparent-background {
  background-color: transparent !important;
  background: transparent !important;
}
</style>
